import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AnswerDto {
  answerId?: Maybe<Scalars['String']>;
  answerOrder?: Maybe<Scalars['Float']>;
  answerText?: Maybe<Scalars['String']>;
};

export class AnsweredQuestionDto {
  answers: Array<AnswerDto>;
  questionId: Scalars['String'];
  questionOrder: Scalars['Float'];
  questionText: Scalars['String'];
};

export class AuthInput {
  email: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class ChangePasswordInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class CreateAdminInput {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export class CreateToolInput {
  toolId: Scalars['String'];
};

export class CreateUserInput {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  profile: Scalars['String'];
};

export class CreateUserTermInput {
  isAwareDataSharedWithDoctor: Scalars['Boolean'];
  isAwareMedicalGuidance: Scalars['Boolean'];
  isAwareTermsOfUse: Scalars['Boolean'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class DirectionInput {
  correctDirection: Scalars['String'];
  direction: Scalars['String'];
};

export class FindStripes {
  isBlocked: Scalars['Boolean'];
  stripe: Stripe;
};

export class FindStripesDto {
  result: Array<FindStripes>;
};

export class FindTools {
  isBlocked: Scalars['Boolean'];
  tool: Tool;
};

export class FindToolsDto {
  result: Array<FindTools>;
};

export class Mutation {
  changePassword: DefaultMessage;
  /** Create new Admin */
  createAdmin: User;
  /** Create new User */
  createUser: User;
  /** Remove Admin By Id */
  deleteAdmin: DefaultMessage;
  forgotPassword: DefaultMessage;
  login: AuthType;
  resetPassword: DefaultMessage;
  /** respond stripe 8 */
  respondStripeEight: DefaultMessage;
  /** respond stripe 5 */
  respondStripeFive: DefaultMessage;
  /** respond stripe 4 */
  respondStripeFour: DefaultMessage;
  /** respond stripe 1 */
  respondStripeOne: DefaultMessage;
  /** respond stripe 7 */
  respondStripeSeven: DefaultMessage;
  /** respond stripe 6 */
  respondStripeSix: DefaultMessage;
  /** respond stripe 3 */
  respondStripeThree: DefaultMessage;
  /** respond stripe 2 */
  respondStripeTwo: DefaultMessage;
  /** Save Search Form */
  saveSearchFormAnswer: UserAnswersDto;
  /** Unlock Tool by toolId */
  unlockTool: DefaultMessage;
  /** Update admin */
  updateAdmin: User;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateAdminArgs {
  data: CreateAdminInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteAdminArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationRespondStripeEightArgs {
  data: RespondStripeInput;
};


export class MutationRespondStripeFiveArgs {
  data: RespondQuestionTextInput;
};


export class MutationRespondStripeFourArgs {
  data: RespondQuestionAnswerInput;
};


export class MutationRespondStripeOneArgs {
  data: RespondQuestionWithTerm;
};


export class MutationRespondStripeSevenArgs {
  data: RespondStripeInput;
};


export class MutationRespondStripeSixArgs {
  data: RespondQuestionDirectionInput;
};


export class MutationRespondStripeThreeArgs {
  data: RespondQuestionTextInput;
};


export class MutationRespondStripeTwoArgs {
  data: RespondQuestionAnswerInput;
};


export class MutationSaveSearchFormAnswerArgs {
  data: SaveQuestionAnswerInput;
};


export class MutationUnlockToolArgs {
  data: CreateToolInput;
};


export class MutationUpdateAdminArgs {
  data: UpdateAdminInput;
};

export class Query {
  /** Return Admin By Id */
  adminById: User;
  /** Return user answer saved */
  answerSaveByUserId?: Maybe<UserAnswersDto>;
  /** Return User Logged */
  currentUser: User;
  /** Return User Intern By id */
  findUserInternById: User;
  /** Return Search Form Question with answers */
  getSearchForm: Array<SearchFormQuestion>;
  stripe: Stripe;
  /** Return User Stripes */
  stripes: FindStripesDto;
  /** Return User Tools */
  tools: FindToolsDto;
  userByGenderDashboard: UserGenderDashboard;
  /** Return User By Id */
  userById: User;
  userPerDayDashboard: Array<UserPerDayDashboard>;
  userSearchFormPerDayDashboard: Array<UserSearchFormDashboard>;
  userStripeCompletedDashboard: Array<UserStripeCompletedDashboard>;
  /** Return All users paged */
  users: UserPage;
  /** Return All users by profile */
  usersByProfile: Array<User>;
};


export class QueryAdminByIdArgs {
  id: Scalars['String'];
};


export class QueryAnswerSaveByUserIdArgs {
  userId: Scalars['String'];
};


export class QueryFindUserInternByIdArgs {
  id: Scalars['String'];
};


export class QueryStripeArgs {
  id: Scalars['String'];
};


export class QueryUserByGenderDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};


export class QueryUserPerDayDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserSearchFormPerDayDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserStripeCompletedDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUsersArgs {
  data: SearchUserInput;
};


export class QueryUsersByProfileArgs {
  profile: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class RespondAnswerInput {
  answerIds: Array<Scalars['String']>;
  questionId: Scalars['String'];
};

export class RespondDirectionInput {
  directions: Array<DirectionInput>;
  order: Scalars['Float'];
};

export class RespondQuestionAnswerInput {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
};

export class RespondQuestionDirectionInput {
  answers: Array<RespondAnswerInput>;
  directions: Array<RespondDirectionInput>;
  stripeId: Scalars['String'];
};

export class RespondQuestionTextInput {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
  texts: Array<RespondTextInput>;
};

export class RespondQuestionWithTerm {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
  terms: CreateUserTermInput;
};

export class RespondStripeInput {
  stripeId: Scalars['String'];
};

export class RespondTextInput {
  questionId: Scalars['String'];
  text: Scalars['String'];
};

export class SaveQuestionAnswerInput {
  answers: Array<SearchFormAnswerInput>;
};

export class SearchDashboardInput {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export class SearchFormAnswerInput {
  answerText?: InputMaybe<Scalars['String']>;
  searchFormQuestionAnswerId?: InputMaybe<Array<Scalars['String']>>;
  searchFormQuestionId: Scalars['String'];
};

export class SearchFormQuestion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  questionOrder: Scalars['Float'];
  searchFormQuestionAnswer?: Maybe<Array<SearchFormQuestionAnswer>>;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SearchFormQuestionAnswer {
  answerOrder: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  searchFormQuestion: SearchFormQuestion;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SearchFormUserAnswer {
  answerText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  searchFormQuestion: SearchFormQuestion;
  searchFormQuestionAnswer?: Maybe<SearchFormQuestionAnswer>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Stripe {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeQuestion?: Maybe<Array<StripeQuestion>>;
  stripeUser?: Maybe<Array<StripeUser>>;
  updatedAt: Scalars['DateTime'];
};

export class StripeDirection {
  correctDirection: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  direction: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeUser: StripeUser;
  updatedAt: Scalars['DateTime'];
};

export class StripeQuestion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  questionDescription?: Maybe<Scalars['String']>;
  questionText: Scalars['String'];
  stripe: Stripe;
  stripeQuestionAnswer?: Maybe<Array<StripeQuestionAnswer>>;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  updatedAt: Scalars['DateTime'];
};

export class StripeQuestionAnswer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeQuestion: StripeQuestion;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class StripeUser {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  stripe: Stripe;
  stripeDirection?: Maybe<Array<StripeDirection>>;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class StripeUserAnswer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  stripeQuestion: StripeQuestion;
  stripeQuestionAnswer?: Maybe<StripeQuestionAnswer>;
  stripeUser: StripeUser;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export class Tool {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  toolUser?: Maybe<Array<ToolUser>>;
  updatedAt: Scalars['DateTime'];
};

export class ToolUser {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  tool: Tool;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UpdateAdminInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  answerSearchFormAt?: Maybe<Scalars['DateTime']>;
  birthday?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnswerSearchForm: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  stripeUser?: Maybe<Array<StripeUser>>;
  toolUser?: Maybe<Array<ToolUser>>;
  updatedAt: Scalars['DateTime'];
  userTerm?: Maybe<Array<UserTerm>>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserAnswersDto {
  questions: Array<AnsweredQuestionDto>;
  user: User;
};

export class UserGenderDashboard {
  qntOther: Scalars['Int'];
  qtdFemale: Scalars['Int'];
  qtdMale: Scalars['Int'];
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserPerDayDashboard {
  createdDate: Scalars['DateTime'];
  qtdUser: Scalars['Int'];
};

export class UserSearchFormDashboard {
  createdDate: Scalars['DateTime'];
  qtdUser: Scalars['Int'];
};

export class UserStripeCompletedDashboard {
  qntCompleted?: Maybe<Scalars['Int']>;
  stripe?: Maybe<Scalars['String']>;
};

export class UserTerm {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAwareDataSharedWithDoctor: Scalars['Boolean'];
  isAwareMedicalGuidance: Scalars['Boolean'];
  isAwareTermsOfUse: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type AuthTypeFragment = { token: string, user: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type UserFragment = { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null };

export type DefaultMessageFragment = { message: string, status: number };

export type LoginMutationVariables = Exact<{
  data: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  type: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type UsersQueryVariables = Exact<{
  data: SearchUserInput;
}>;


export type UsersQuery = { users: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results?: Array<{ birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }> | null } };

export type CreateAdminMutationVariables = Exact<{
  data: CreateAdminInput;
}>;


export type CreateAdminMutation = { createAdmin: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type UpdateAdminMutationVariables = Exact<{
  data: UpdateAdminInput;
}>;


export type UpdateAdminMutation = { updateAdmin: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, gender?: string | null, id: string, name: string, phone: string, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAdminMutation = { deleteAdmin: { message: string, status: number } };

export type UserByGenderDashboardQueryVariables = Exact<{
  data: SearchDashboardInput;
}>;


export type UserByGenderDashboardQuery = { userByGenderDashboard: { qtdFemale: number, qtdMale: number, qntOther: number } };

export type UserPerDayDashboardQueryVariables = Exact<{
  data: SearchDashboardInput;
}>;


export type UserPerDayDashboardQuery = { userPerDayDashboard: Array<{ createdDate: any, qtdUser: number }> };

export type UserStripeCompletedDashboardQueryVariables = Exact<{
  data: SearchDashboardInput;
}>;


export type UserStripeCompletedDashboardQuery = { userStripeCompletedDashboard: Array<{ qntCompleted?: number | null, stripe?: string | null }> };

export type UserSearchFormPerDayDashboardQueryVariables = Exact<{
  data: SearchDashboardInput;
}>;


export type UserSearchFormPerDayDashboardQuery = { userSearchFormPerDayDashboard: Array<{ qtdUser: number, createdDate: any }> };

export const UserFragmentDoc = gql`
    fragment User on User {
  birthday
  createdAt
  deletedAt
  email
  gender
  id
  name
  phone
  photoUrl
  profile
  resetPasswordExpiration
}
    `;
export const AuthTypeFragmentDoc = gql`
    fragment AuthType on AuthType {
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const LoginDocument = gql`
    mutation login($data: AuthInput!) {
  login(data: $data) {
    ...AuthType
  }
}
    ${AuthTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!, $type: String!) {
  forgotPassword(email: $email, type: $type) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($data: SearchUserInput!) {
  users(data: $data) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAdminDocument = gql`
    mutation createAdmin($data: CreateAdminInput!) {
  createAdmin(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdminGQL extends Apollo.Mutation<CreateAdminMutation, CreateAdminMutationVariables> {
    document = CreateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdminDocument = gql`
    mutation updateAdmin($data: UpdateAdminInput!) {
  updateAdmin(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdminGQL extends Apollo.Mutation<UpdateAdminMutation, UpdateAdminMutationVariables> {
    document = UpdateAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdminDocument = gql`
    mutation deleteAdmin($id: String!) {
  deleteAdmin(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdminGQL extends Apollo.Mutation<DeleteAdminMutation, DeleteAdminMutationVariables> {
    document = DeleteAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserByGenderDashboardDocument = gql`
    query userByGenderDashboard($data: SearchDashboardInput!) {
  userByGenderDashboard(data: $data) {
    qtdFemale
    qtdMale
    qntOther
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserByGenderDashboardGQL extends Apollo.Query<UserByGenderDashboardQuery, UserByGenderDashboardQueryVariables> {
    document = UserByGenderDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPerDayDashboardDocument = gql`
    query userPerDayDashboard($data: SearchDashboardInput!) {
  userPerDayDashboard(data: $data) {
    createdDate
    qtdUser
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPerDayDashboardGQL extends Apollo.Query<UserPerDayDashboardQuery, UserPerDayDashboardQueryVariables> {
    document = UserPerDayDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserStripeCompletedDashboardDocument = gql`
    query userStripeCompletedDashboard($data: SearchDashboardInput!) {
  userStripeCompletedDashboard(data: $data) {
    qntCompleted
    stripe
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserStripeCompletedDashboardGQL extends Apollo.Query<UserStripeCompletedDashboardQuery, UserStripeCompletedDashboardQueryVariables> {
    document = UserStripeCompletedDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserSearchFormPerDayDashboardDocument = gql`
    query userSearchFormPerDayDashboard($data: SearchDashboardInput!) {
  userSearchFormPerDayDashboard(data: $data) {
    qtdUser
    createdDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSearchFormPerDayDashboardGQL extends Apollo.Query<UserSearchFormPerDayDashboardQuery, UserSearchFormPerDayDashboardQueryVariables> {
    document = UserSearchFormPerDayDashboardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }